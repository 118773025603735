<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="45%"
          height="45%"
          alt="OneBox"
        />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center">
          <v-icon color="error">error</v-icon>&nbsp;{{ errormessage }}
        </p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage: "",
    };
  },
  created() {},
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  methods: {
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      console.log(
        "this.$route.query.accesstoken",
        this.$route.query.accesstoken
      );
      //เช็คว่ามี accesstoken มั้ย
      if (!this.$route.query.accesstoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!";
      } else {
        let _accesstoken = this.$route.query.accesstoken;
        let _biz_id = this.$route.query.biz_id;

        let payload = {
          accesstoken: _accesstoken,
        };

        // 1 ยิง API getprofile
        let check_auth = await this.$store.dispatch(
          "authorize_login_accesstoken",
          payload
        );

        if (check_auth.status === "Access Granted") {
          // ส่ง event ไปยัง Google Analytics
          this.$gtag.event("login", {
            event_category: "default_accesstoken",
          });

          let redirect_to = "myfiles";
          if (this.$route.query.redirect_to) {
            redirect_to = this.$route.query.redirect_to;
          }

          let databusinessIndex = this.dataBusinessProfile.findIndex(
            (item) => item.id === _biz_id
          );
          let payload_bu;
          if (databusinessIndex >= 0 && _biz_id) {
            payload_bu = {
              accesstoken: this.dataAccesstoken,
              biz_id: this.dataBusinessProfile[databusinessIndex].id,
              branch_no: this.dataBusinessProfile[databusinessIndex].branch_no,
              taxid: this.dataBusinessProfile[databusinessIndex].id_card_num,
            };
          } else if (this.dataLoginDefault) {
            payload_bu = {
              accesstoken: this.dataAccesstoken,
              biz_id: this.dataLoginDefault.id,
              branch_no: this.dataLoginDefault.branch_no,
              taxid: this.dataLoginDefault.id_card_num,
            };
          } else {
            this.$router.push({ name: redirect_to });
            return;
          }

          let check_auth_bu = await this.$store.dispatch(
            "switchaccount_business",
            payload_bu
          );
          console.log("check_auth_bu.status", check_auth_bu.status);
          if (check_auth_bu.status === "Business Access Granted") {
            console.log("dataAccountActive", this.dataAccountActive);
            if (redirect_to === "myfiles") {
              this.$router.push({
                name: "directory",
                params: {
                  id: this.dataAccountActive.business_info.my_folder_id,
                },
              });
            } else {
              this.$router.push({ name: redirect_to });
            }
          } else {
            this.$router.push({ name: redirect_to });
          }
        } else {
          this.fail_ = true;
          this.errormessage = "ไม่สามารถ login ได้ !!";
        }
      }
    },
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.reset_login();
  },
};
</script>
